import { useContext, useEffect } from "react";
import { GenContext } from "../../gen-state/gen.context";
import { ethers } from "ethers";
import { useState } from "react";
import Abi from "./contractAbi.json";
import classes from "./mint.module.css";
// layout
import AppLayout from "../../layouts/AppLayout";
import { AbiItem } from "web3-utils";
import img from "../../assets/images/her_1.png";
import minus from "../../assets/images/minus.svg";
import plus from "../../assets/images/plus.svg";
import Clipboard from '../../components/Clipboard/clipboard';
import countDown from "../Home/CountDownSection";
//  Create WalletConnect Provider

// declare global {
//   interface Window {
//     ethereum?: any;
//     web3?:any
//   }
// }

const Mint = () => {
  const startDate = new Date("Apr 1, 2022").getTime();
  const [state, setState] = useState({
    amount: 1,
  });
  const { amount } = state;
  const handleSetState = (payload: any) => {
    setState((state) => ({ ...state, ...payload }));
  };
  const minterAddress: any = "0xB01767bf57145D4762bd011366A877c8eB91c835";
  const [quantity, setQuantity] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [openseaLink, setOpenseaLink] = useState('')
  const [message, setMessage] = useState('')
  const [mintedQuantity, setMintedQuantity] = useState(0)
  const { account, connector, dispatch } = useContext(GenContext);


  useEffect(() => {
    // handleSetState({ timeleft: startDate - Date.now() });

    // if process.env
    const timeleft = startDate - Date.now();
    if (process.env.REACT_APP_ALCHEMY_KEY && process.env.REACT_APP_SERVER_KEY) {
      let provider = new ethers.providers.AlchemyProvider("matic", process.env.REACT_APP_ALCHEMY_KEY)
      let wallet = new ethers.Wallet(process.env.REACT_APP_SERVER_KEY as any, provider)
      const lContract = new ethers.Contract(minterAddress, Abi, wallet)
      let supply = lContract.totalSupply().then((data:any) =>{
      setMintedQuantity(data.toString())
    });
    }
    
    // if (timeleft > 0) {
    //   setInterval(() => {
    //     const timeleft = startDate - Date.now();
    //     const day = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    //     const hour = Math.floor(
    //       (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    //     );
    //     const minute = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    //     const second = Math.floor((timeleft % (1000 * 60)) / 1000);
    //     handleSetState({
    //       days: day,
    //       hours: hour,
    //       minutes: minute,
    //       seconds: second,
    //     });
    //   }, 1000);
    // }
  }, [mintedQuantity]);
  const handleQuantity = (evt: any) => {
    evt.preventDefault();
    try {
      let quant = evt.target.value;
      setQuantity(quant);
      console.log(quant)
      handleSetState({ amount: quant})
      setDisabled(false);
    } catch (e: any) {
      setQuantity(evt.target.value);
      setDisabled(true);
    }
  };

  // async function run(){
  //   //  Create WalletConnect Provider
  //   const provider = new WalletConnectProvider({
  //     infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
  //   });

  //   //  Enable session (triggers QR Code modal)
  //   await provider.enable();
  // }

  async function faucet() {
    if (!connector) {
      alert("please connect your wallet");
      return;
    }
    const provider = new ethers.providers.Web3Provider(connector);
    const signer = provider.getSigner();

    if (connector.isWalletConnect) {
      if (connector.chainId !== 137) {
        alert("change network to polygon");
        return;
      }
      // const signer = connector.getSigner();
      // conole.log('.....', signer)
      const contract = new ethers.Contract(minterAddress, Abi, signer);
      contract.on("Transfer", (rd, rc, id) => {
        if (rd === 0) {
          contract.totalSupply().then((supply: any) =>{setMintedQuantity(supply.toString())});
        }
      })
      const pricePerToken = (await contract.NFT_PRICE()) / 10 ** 18;
      // const binge = Web3.utils.toBN(ethers.utils.parseEther((pricePerToken*quantity).toString()))
     
      const ethNonce = await signer.getTransactionCount();
      const tx: ethers.providers.TransactionRequest = {
        from: account,
        to: minterAddress,
        // gasLimit: ethers.utils.hexlify(250000),
        // gasPrice: ethers.utils.parseUnits('5', "gwei"),
        value: ethers.utils.parseEther((pricePerToken * amount).toString()),
        data: contract.interface.encodeFunctionData("mint", [amount]),
        nonce: ethNonce,
      };
      try {
        const result = await signer.sendTransaction(tx);
        setMessage(`https://polygonscan.com/tx/${result.hash}`);
      } catch (error) {
        alert(error);
        return;
      }
      return;
    } else {
      // const signer = provider.eth.getAccounts;
      const network = await provider.getNetwork();
      if (network.chainId !== 137) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      }
      const contract = new ethers.Contract(minterAddress, Abi, signer);
      contract.on("Transfer", (rd, rc, id) => {
        if (rd === 0) {
          contract.totalSupply().then((supply: any) =>{setMintedQuantity(supply.toString())});
        }
      })
      const pricePerToken = (await contract.NFT_PRICE()) / 10 ** 18;

      try {
        const tx = await contract.mint(amount, {
          value: ethers.utils.parseEther((pricePerToken * amount).toString()),
        });
        await tx.wait();
        setMessage(`https://polygonscan.com/tx/${tx.hash}`);
      } catch (error: any) {
        if (error.code === 4001) {
          alert("You cancelled the transaction!");
        } else {
          alert(error.data.message);
        }
      }
    }
  }

  return (
    <AppLayout showFooter={false}>
      <div className={`${classes.container} "Mint"`}>
        <div className={`${classes.pink} Mint__inner`}>
          <div className={classes.top}>
            <div className={classes.her}>
              <img src="/img/her1.gif" alt="black_her" />
            </div>

            <div className={classes.mint}>
              <div className={classes.title}>
                H.E.R DAO + Minority Programmers Mint
              </div>
              <div className={classes.subtitle}>{mintedQuantity}/2,222</div>
              <div className={classes.input}>
                <img
                  onClick={
                    amount > 0
                      ? () => handleSetState({ amount: amount - 1 })
                      : () => handleSetState({ amount: 0 })
                  }
                  src={minus}
                  className={classes.minus}
                  alt=""
                />
                <input
                  type="number"
                  min={0}
                  onChange={handleQuantity}
                  value={amount}
                  placeholder="Enter amount of NFT to mint"
                />
                <img
                  onClick={() => {
                    handleSetState({ amount: amount + 1 })
                  }}
                  src={plus}
                  className={classes.plus}
                  alt=""
                />
              </div>
              <div className={classes.sub2}>
                1 NFT costs 200 MATIC. Excluding gas fees.
              </div>
              {/* <div onClick={faucet}>Mint </div> */}
              <button onClick={faucet} className={classes.btn}>
                Mint
              </button>
            </div>
          </div>
          {/* <div className={classes.countdown}>
            <div className={classes.time}>
              <span>{days}</span> <span>days</span>
            </div>
            :
            <div className={classes.time}>
              <span>{hours}</span> <span>hours</span>
            </div>
            :
            <div className={classes.time}>
              <span>{minutes}</span> <span>minutes</span>
            </div>
            :
            <div className={classes.time}>
              <span>{seconds}</span> <span>seconds</span>
            </div>
          </div> */}
        </div>
        <Clipboard  clipboardMessage={openseaLink} notification={openseaLink} loaderMessage=""/>
        <Clipboard  clipboardMessage={message}/>
      </div>
    </AppLayout>
  );
};

export default Mint;
