import React, { useEffect, useState, useContext } from "react";
import { gsap, Power3 } from "gsap";
import { MouseContext } from "../../context/mouse-context";
import { GenContext } from "../../gen-state/gen.context";
import { setConnector, setAccount } from "../../gen-state/gen.actions";

import MenuIcon from "../Icons/MenuIcon";
import CloseIcon from "../Icons/CloseIcon";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import Web3 from "web3";
import Web3Modal from "web3modal";

interface NavbarProps {}

const Navbar = (props: NavbarProps): JSX.Element => {
  let [wallet, setWallet] = useState("Connect Wallet");
  const { cursorChangeHandler } = useContext(MouseContext);
  const { dispatch, connector, account } = useContext(GenContext);

  const MobileNavbar = () => {
    return account ? (
      <div className="MobileNavbar">
        <ul className="MobileNavbar__links">
          <div>
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                className="uppercase Navbar__menu-link"
                href="/#about"
                onClick={handleClick}
              >
                ABOUT
              </a>
            </li>
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                className="uppercase Navbar__menu-link"
                href="/#roadmap"
                onClick={handleClick}
              >
                ROADMAP
              </a>
            </li>
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
            <div className="stack">
              <button className="Navbar__menu-link" onClick={toggleWallet}>
                {breakAddress(account)}
              </button>
              <button
                className="Navbar__menu-link"
                id="btn2"
                onClick={disconnect}
              >
                disconnect
              </button>
            </div>
            </li>
            
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                className="uppercase Navbar__menu-link"
                href="/#faq"
                onClick={handleClick}
              >
                FAQ
              </a>
            </li>
          </div>
          <div className="MobileNavbar__social-links">
            <a
              href="https://discord.gg/4vdtmQqz6d"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <img alt="" src="/icons/discord.svg" />
            </a>
            <a
              href="https://twitter.com/minorityprogram"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <img alt="" src="/icons/twitter.svg" />
            </a>
            <a
              href="https://www.instagram.com/minorityprogrammers"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <img alt="" src="/icons/instagram.svg" />
            </a>
          </div>
        </ul>
      </div>
    ) : (
      <div className="MobileNavbar">
        <ul className="MobileNavbar__links">
          <div>
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                className="uppercase Navbar__menu-link"
                href="/#about"
                onClick={handleClick}
              >
                ABOUT
              </a>
            </li>
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                className="uppercase Navbar__menu-link"
                href="/#roadmap"
                onClick={handleClick}
              >
                ROADMAP
              </a>
            </li>
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
            <button className="Navbar__menu-link" onClick={toggleWallet}>
              {wallet}
            </button>
            </li>
            
            <li
              className="MobileNavbar__menuLink"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                className="uppercase Navbar__menu-link"
                href="/#faq"
                onClick={handleClick}
              >
                FAQ
              </a>
            </li>
          </div>
          <div className="MobileNavbar__social-links">
            <a
              href="https://discord.gg/4vdtmQqz6d"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <img alt="" src="/icons/discord.svg" />
            </a>
            <a
              href="https://twitter.com/minorityprogram"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <img alt="" src="/icons/twitter.svg" />
            </a>
            <a
              href="https://www.instagram.com/minorityprogrammers"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => cursorChangeHandler("hovered")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <img alt="" src="/icons/instagram.svg" />
            </a>
          </div>
        </ul>
      </div>
    );
  };

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    gsap.from(".Navbar__logo", {
      delay: 0.3,
      opacity: 0,
      scale: 0.5,
      ease: Power3.easeOut,
    });
  }, []);

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          137: "https://polygon-mainnet.g.alchemy.com/v2/DBIzFmV2PWp5688EODsv26Gm7MYqUhBE",
          4160: "https://api.testnet.algoexplorer.io",
        },
        rpcUrl: "",
      },
    },
  };

  const web3Modal = new Web3Modal({
    // network: "mainnet", // optional
    // cacheProvider: true, // optional
    providerOptions, // required
  });

  async function disconnect() {
    if (connector) {
      const clear = await web3Modal.clearCachedProvider();
      setWallet("Connect Wallet");
      await dispatch(setConnector(null));
      dispatch(setAccount(""));
    }
  }
  function breakAddress(address = "", width = 6) {
    return `${address.slice(0, width)}...${address.slice(-width)}`;
  }

  const toggleWallet = async (e: any) => {
    // bridge url
    // const bridge = "https://bridge.walletconnect.org";

    let connector;
    try {
      connector = await web3Modal.connect();
    } catch (error) {
      alert(error);
      return;
    }

    console.log("ec2? Meta??", connector, connector.selectedAddress);

    // const provider = new ethers.providers.Web3Provider(connector);

    // const signer = provider.getSigner();
    // console.log('info', provider, signer)

    await dispatch(setConnector(connector));

    if (connector.isWalletConnect) {
      if (!connector.connected) {
        // create new session
        await connector.createSession();
      }
      // Subscribe to connection events
      connector.on("connect", (error: any, payload: any) => {
        if (error) {
          throw error;
        }

        // Get provided accounts
        const { accounts } = payload.params[0];
        console.log(payload.params, accounts);
        dispatch(setAccount(accounts[0]));
      });

      connector.on("session_update", (error: any, payload: any) => {
        console.log("what updated");
        if (error) {
          throw error;
        }

        // Get updated accounts
        const { accounts } = payload.params[0];
        dispatch(setAccount(accounts[0]));
      });

      if (connector.connected) {
        const { accounts } = connector;
        dispatch(setAccount(accounts[0]));
      }

      connector.on("disconnect", (error: any, payload: any) => {
        if (error) {
          throw error;
        }
      });
    } else {
      dispatch(setAccount(connector.selectedAddress));
      connector.on("accountsChanged", (accounts: any) => {
        console.log("I WANNA SHOUT");
        dispatch(setAccount(accounts[0]));
      });
    }
    // check if already connected
  };
  return account ? (
    <nav className="Navbar">
      <div className="px-5 Navbar__inner Main__Margin lg:px-4">
        {/* Logo */}
        <div className="Navbar__logo">
          <a href="/">
            <img src="/img/MinorityDropLogo.png" alt="Minority x H.E.R. logo" />
          </a>
        </div>

        {/* Hamburger Menu & Close Icon */}
        <button
          onClick={handleClick}
          className="Navbar__menuIcon"
          aria-label="Menu"
        >
          {openMenu ? <CloseIcon /> : <MenuIcon />}
        </button>

        {/* Menu Links on Desktop */}
        <div className="Navbar__links">
          <div className="Navbar__menu-links">
            <a className="Navbar__menu-link" href="/#about">
              ABOUT
            </a>
            <a className="Navbar__menu-link" href="/#roadmap">
              ROADMAP
            </a>
            <a className="Navbar__menu-link" href="/#faq">
              FAQ
            </a>
            <a className="Navbar__menu-link" href="/mint">
              MINT
            </a>
            <div className="stack">
              <button className="Navbar__menu-link" onClick={toggleWallet}>
                {breakAddress(account)}
              </button>
              <button
                className="Navbar__menu-link"
                id="btn2"
                onClick={disconnect}
              >
                disconnect
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Menu Links on Mobile */}
      {openMenu && <MobileNavbar />}
    </nav>
  ) : (
    <nav className="Navbar">
      <div className="px-5 Navbar__inner Main__Margin lg:px-4">
        {/* Logo */}
        <div className="Navbar__logo">
          <a href="/">
            <img src="/img/MinorityDropLogo.png" alt="Minority x H.E.R. logo" />
          </a>
        </div>

        {/* Hamburger Menu & Close Icon */}
        <button
          onClick={handleClick}
          className="Navbar__menuIcon"
          aria-label="Menu"
        >
          {openMenu ? <CloseIcon /> : <MenuIcon />}
        </button>

        {/* Menu Links on Desktop */}
        <div className="Navbar__links">
          <div className="Navbar__menu-links">
            <a className="Navbar__menu-link" href="/#about">
              ABOUT
            </a>
            <a className="Navbar__menu-link" href="/#roadmap">
              ROADMAP
            </a>
            <a className="Navbar__menu-link" href="/#faq">
              FAQ
            </a>
            <a className="Navbar__menu-link" href="/mint">
              MINT
            </a>
            {/* <a className="Navbar__menu-link Viwer" target="_blank" rel="noopener noreferrer" href="https://opensea.io/collection/h-e-r-x-minority">
            View Opensea Collection
          </a> */}
            <button className="Navbar__menu-link" onClick={toggleWallet}>
              {wallet}
            </button>
          </div>
        </div>
      </div>
      {/* Menu Links on Mobile */}
      {openMenu && <MobileNavbar />}
    </nav>
  );
};

export default Navbar;
