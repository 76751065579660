import { useContext } from 'react';
import { GenContext } from '../../gen-state/gen.context';
import { ethers } from "ethers";
import { useState } from "react";
import Abi from "./contractAbi.json";

// layout
import AppLayout from "../../layouts/AppLayout";


//  Create WalletConnect Provider

// declare global {
//   interface Window {
//     ethereum?: any;
//     web3?:any
//   }
// }

const Mint = () => {
  const minterAddress = "0xE8b8Ed73b19f63a056c678237BeeBe61B8E7523a";
  const [quantity, setQuantity] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const { account, connector, dispatch } = useContext(GenContext);

  const handleQuantity = (evt: any) => {
    evt.preventDefault();
    console.log(account, connector)
    try {
        let validatedAddress = evt.target.value
        setQuantity(validatedAddress);
        setDisabled(false)
    } catch (e: any) {
        setQuantity(evt.target.value)
        setDisabled(true)
    }
  }

  // async function run(){
  //   //  Create WalletConnect Provider
  //   const provider = new WalletConnectProvider({
  //     infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
  //   });

  //   //  Enable session (triggers QR Code modal)
  //   await provider.enable();
  // }

  

  async function faucet() {
    if (!connector) {
      alert("please connect your wallet");
      return;
    }
    const provider = new ethers.providers.Web3Provider(connector)
    const signer = provider.getSigner();

    if (connector.isWalletConnect) {
      if (connector.chainId !== 137) {
        alert("change network to polygon")
        return;
      }
      // const signer = connector.getSigner();
      // console.log('.....', signer)
      const contract = new ethers.Contract(minterAddress, Abi, signer);
      const pricePerToken = await contract.NFT_PRICE() / 10**18
      console.log('mint price', ethers.utils.parseEther((pricePerToken*quantity).toString()).toString())
      // const binge = Web3.utils.toBN(ethers.utils.parseEther((pricePerToken*quantity).toString()))
      console.log('aca', account)
      const ethNonce = await signer.getTransactionCount()
      console.log('NONCE', ethNonce)
      const tx: ethers.providers.TransactionRequest = {
        from: account,
        to: minterAddress,
        // gasLimit: ethers.utils.hexlify(250000),
        // gasPrice: ethers.utils.parseUnits('5', "gwei"),
        value: ethers.utils.parseEther((pricePerToken*quantity).toString()),
        data: contract.interface.encodeFunctionData("mint", [quantity]),
        nonce: ethNonce
      }
      try {
        const result = await signer.sendTransaction(tx)
        if (window.confirm("click ok to view the collection on opensea")) {
          window.open(`https://testnets.opensea.io/collection/testherdrop`)
        }
      } catch (error) {
        alert(error)
        return
      }
      return
    } else {
      // const signer = provider.eth.getAccounts;
        const contract = new ethers.Contract(minterAddress, Abi, signer);
        const pricePerToken = await contract.NFT_PRICE() / 10**18
        const network = await provider.getNetwork();
        if (network.chainId !== 80001) {
          alert("change to polygon testnet");
          return;
        }
        try {
            const tx = await contract.mint(quantity, { value: ethers.utils.parseEther((pricePerToken*quantity).toString()) });
            await tx.wait()
            if (window.confirm("click ok to view the collection on opensea")) {
              window.open(`https://testnets.opensea.io/collection/testherdrop`)
            }
        } catch (error: any) {
          console.log(error);
            if (error.code === 4001) {
                alert("You cancelled the transaction!")
            } else {
                alert(error.data.message)
            }
        }
    }
        
}

  return (
    <AppLayout showFooter={false}>
      <div className="Mint">
        <div className="Mint__inner">
          <div className="HeroSection__image">
          <img src="/img/her1.gif" alt="Minority X HER Drop Preview Gif" />
          {/* <iframe loading="lazy" width="100vw" height="100vh"  src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAE1mKBHfKg&#x2F;view?embed"></iframe>  */}

        </div>
          <input type="number" min="0" onChange={handleQuantity} placeholder="enter amount to mint" style={{
              color: "#446396",
              padding: "2px",
              textAlign: "center",
              border: "2px solid",
              margin: "0px 0px 0px 0px",
              height: "50px"
            }} />
          <p 
          style={{
            margin: "15px 0 0px 0px"
          }}
          >
            <button 
            style={{
              color: "#446396",
              border: "1px solid",
              margin: "0px 0px 0px 0px"
            }}
            onClick={faucet}
            disabled={disabled}
            type="button"
            >
            Mint your Minority x H.E.R.. 
            </button>
          </p>
        </div>
      </div>
    </AppLayout>
  );
};

export default Mint;
