import React from "react";

interface FooterProps {}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div className="Footer">
      <div className="Footer__inner">
        <div className="Footer__social-links">
          <a
            href="https://discord.gg/tMZSEtPjdG"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="H.E.R. DAO Discord" src="/icons/discord.svg" />
          </a>
          <a
            href="https://twitter.com/_HerDAO"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="H.E.R. DAO Twitter" src="/icons/twitter.svg" />
          </a>
          <a
            href="https://www.instagram.com/h.e.r.dao"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="H.E.R. DAO Instagram Icon" src="/icons/instagram.svg" />
          </a>
          <a
            href="https://linktr.ee/H.E.R.DAO"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="H.E.R. DAO Linktree" src="/icons/globe.svg" />
          </a>
        </div>
        <div className="Footer__content">
          <a
            href="https://www.minorityprogrammers.com/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            with ❤️ by the Minority Programmers
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
