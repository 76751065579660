import React from "react";
import TweetEmbed from "react-tweet-embed";
import classes from "./Review.module.css";

const Review = (): JSX.Element => {
  const reviews = [
    "1509745985324560385", // near announcement
    "1510600098245447682",
    "1510644455044489225",
    "1510474299806732292",
    "1509738302357876743",
    "1510975702186741771",
  ];

  return (
    <div className={classes.container}>
      <div className={classes.heading}>People Love the Drop</div>
      <div className={classes.description}>
        See what the buzz about the H.E.R. x MINORITY drop is all about
      </div>

      <div className={classes.review}>
        {reviews.map((tweet) => (
          <span key={tweet} className={classes.tweet}>
            <TweetEmbed id={tweet} placeholder="loading" />
          </span>
        ))}
      </div>
    </div>
  );
};

export default Review;
