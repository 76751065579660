import React, { Suspense } from "react";
import Lottie from "react-lottie";
import loader from "../../assets/loader/loader.json";

// layouts
import AppLayout from "../../layouts/AppLayout";
import Review from "./Review/Review";

const HeroSection = React.lazy(() => import("./HeroSection"));
const CountDownSection = React.lazy(() => import("./CountDownSection"));
const GallerySection = React.lazy(() => import("./GallerySection"));
const AboutSection = React.lazy(() => import("./CountDownSection"));
const RoadmapSection = React.lazy(() => import("./RoadmapSection"));
const FaqSection = React.lazy(() => import("./FaqSection"));

interface HomeProps {}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Home = (props: HomeProps) => {
  return (
    <AppLayout showNavbar={false}>
      <div className="Home">
        <div className="Home__inner">
          <Suspense
            fallback={
              <div className="Home__loading">
                <Lottie options={defaultOptions} height={150} width={150} />
              </div>
            }
          >
            <HeroSection />
            {/* <CountDownSection /> */}
            <GallerySection />
            <Review />

            {/* <AboutSection /> */}
            <RoadmapSection />
            <FaqSection />
          </Suspense>
        </div>
      </div>
    </AppLayout>
  );
};

export default Home;
